import { gql } from "@apollo/client";
import { DEFAULT_USER_SETTINGS, UserId, UserSettings } from "@hex/common";

import { useGetUserForSettingsQuery } from "./useUserForSettings.generated";

gql`
  query GetUserForSettings {
    me {
      id
      magicAutoRunOnKeep
      vimMode
      userSettings
    }
  }
`;

export function useUserForSettings(): {
  magicAutoRunOnKeep: boolean;
  vimMode: boolean;
  userSettings: UserSettings;
  id: UserId | undefined;
} {
  const { data } = useGetUserForSettingsQuery();

  const userSettings = data?.me?.userSettings ?? {};
  return {
    magicAutoRunOnKeep: data?.me?.magicAutoRunOnKeep ?? false,
    vimMode: data?.me?.vimMode ?? false,
    userSettings: { ...DEFAULT_USER_SETTINGS, ...userSettings },
    id: data?.me?.id,
  };
}
