import { AppTheme } from "@hex/common";

import { Theme } from "../theme/common/theme";
import { useDarkTheme } from "../theme/themes/darkTheme";
import { useLightTheme } from "../theme/themes/lightTheme";

import { usePrefersColorScheme } from "./usePrefersColorScheme";

export function useGetThemeFromName(themeName: AppTheme): Theme {
  const preferredColorScheme = usePrefersColorScheme();
  const lightTheme = useLightTheme();
  const darkTheme = useDarkTheme();

  if (themeName === AppTheme.SYS_PREF) {
    return preferredColorScheme === AppTheme.DARK ? darkTheme : lightTheme;
  }

  return themeName === AppTheme.DARK ? darkTheme : lightTheme;
}
