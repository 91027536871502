import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { useDispatch, useSelector } from "../redux/hooks.js";
import { setFocusedView } from "../redux/slices/logicViewSlice.js";
import { useQueryParams } from "../route/routes.js";
import {
  DEFAULT_VIEW,
  EditViewType,
  VIEW_SEPARATOR,
} from "../util/projectViewTypes.js";

interface ProjectViewsReturn {
  openViews: EditViewType[];
  focusedView: EditViewType | null;
  inNotebookView: boolean;
  inGraphView: boolean;
  inAppView: boolean;
  hasNotebookViewOpen: boolean;
  hasGraphViewOpen: boolean;
  hasAppViewOpen: boolean;
  changeView: (replace: boolean, thisView: EditViewType) => void;
}

export function useProjectViews(): ProjectViewsReturn {
  const history = useHistory();
  const queryParams = useQueryParams();
  const paramViews = queryParams.get("view") || DEFAULT_VIEW;
  const [openViews, setOpenViews] = useState<EditViewType[]>([]);

  // Toggle view as focused if any clicks inside
  const dispatch = useDispatch();
  const focusedView = useSelector((state) => state.logicView.focusedView);
  const setFocusedViewCallback = useCallback(
    (view: EditViewType) => {
      if (focusedView !== view) {
        dispatch(setFocusedView(view));
      }
    },
    [dispatch, focusedView],
  );

  useEffect(() => {
    if (paramViews != null) {
      const selViews = paramViews
        .split(VIEW_SEPARATOR)
        .map((item) => item as EditViewType);
      setOpenViews(selViews);
      setFocusedViewCallback(selViews[0]);
    }
    // Not passing in `setFocusedViewCallback` to deps as it causes this useEffect to call to frequently
    // eslint-disable-next-line react-hooks/exhaustive-deps -- see above
  }, [paramViews]);

  const changeView = useCallback(
    (replace: boolean, thisView: EditViewType) => {
      let newViews = "";

      if (replace) {
        newViews = thisView;
      } else {
        // add
        if (!openViews.includes(thisView)) {
          newViews = [...openViews, thisView].join(VIEW_SEPARATOR);

          // remove
        } else {
          const tempViews = [...openViews];
          const index = tempViews.indexOf(thisView);
          tempViews.splice(index, 1);
          newViews = tempViews.join(VIEW_SEPARATOR);
        }
      }

      // Push to url
      queryParams.set("view", newViews);
      history.push({ search: queryParams.toString() });
    },
    [openViews, queryParams, history],
  );

  const state = useMemo(() => {
    return {
      openViews,
      focusedView,
      changeView,
      inNotebookView: focusedView === EditViewType.NOTEBOOK,
      inGraphView: focusedView === EditViewType.GRAPH,
      inAppView: focusedView === EditViewType.APP,
      hasNotebookViewOpen: openViews.includes(EditViewType.NOTEBOOK),
      hasGraphViewOpen: openViews.includes(EditViewType.GRAPH),
      hasAppViewOpen: openViews.includes(EditViewType.APP),
    };
  }, [changeView, focusedView, openViews]);

  return state;
}
