import { useCallback, useEffect, useState } from "react";

import { logErrorMsg } from "../util/logging";

export enum StorageAccessState {
  HAS_ACCESS = "HAS_ACCESS",
  NO_ACCESS = "NO_ACCESS",
  LOADING = "LOADING",
  FAILURE = "FAILURE",
}

const hasStorageApis =
  document.hasStorageAccess != null && document.requestStorageAccess != null;

/**
 * This should only be called outside of a user action
 * so we don't accidentally consume it and prevent being
 * able to request storage access.
 */
async function checkStorageAccess(): Promise<StorageAccessState> {
  // Most browsers don't support the storage access API yet,
  // so we just assume access in these cases since they don't
  // restrict access to 3rd party cookies
  if (!hasStorageApis) {
    return StorageAccessState.HAS_ACCESS;
  } else {
    try {
      const hasAccess = await document.hasStorageAccess();
      return hasAccess
        ? StorageAccessState.HAS_ACCESS
        : StorageAccessState.NO_ACCESS;
    } catch (err) {
      logErrorMsg(err, "storage access failure");
      return StorageAccessState.FAILURE;
    }
  }
}

export interface HasStorageAccessResult {
  storageAccessState: StorageAccessState;
  /**
   * This _must_ be called only as a part of an explicit
   * user action, such as clicking on a button.
   */
  requestStorageAccess: () => Promise<void>;
}

/**
 * Some browsers (Firefox, Safari) don't allow access to
 * third-party cookies when embeded. This breaks our authn
 * for non public projects/apps. This hook allows you to
 * check and request storage access when embeded.
 *
 * NOTE: if users embedding projects/apps are using the
 * `sandbox` attribute will need to include the sandbox token
 * `allow-storage-access-by-user-activation` for this to work.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage_Access_API
 */
export function useHasStorageAccess(): HasStorageAccessResult {
  const [storageAccessState, setStorageAccessState] = useState(
    StorageAccessState.LOADING,
  );

  useEffect(() => {
    let cancelled = false;
    void checkStorageAccess().then((state) => {
      if (!cancelled) {
        setStorageAccessState(state);
      }
    });
    return () => {
      cancelled = true;
    };
  }, []);

  const requestStorageAccess = useCallback(async () => {
    try {
      await document.requestStorageAccess();
      setStorageAccessState(StorageAccessState.HAS_ACCESS);
    } catch (_err) {
      setStorageAccessState(StorageAccessState.NO_ACCESS);
    }
  }, []);

  return { storageAccessState, requestStorageAccess };
}
