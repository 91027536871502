import { useCallback } from "react";

import {
  DEFAULT_SIDEBAR_WIDTH,
  SidebarTab,
} from "../components/sidebar/SidebarOptions";

import {
  LocalStorageKeys,
  useLocalStorageGetter,
  useLocalStorageSetter,
} from "./useLocalStorage";

export function useOpenSidebar(tab: SidebarTab): () => void;
export function useOpenSidebar(): (tab: SidebarTab) => void;
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useOpenSidebar(tab?: SidebarTab) {
  const setSidebarTab = useLocalStorageSetter(LocalStorageKeys.SIDEBAR_VIEW);
  const setSidebarWidth = useLocalStorageSetter(LocalStorageKeys.SIDEBAR_WIDTH);

  const isDrawerViewCollapsed = useLocalStorageGetter(
    LocalStorageKeys.OUTLINE_COLLAPSED,
  );
  const setDrawerViewCollapsed = useLocalStorageSetter(
    LocalStorageKeys.OUTLINE_COLLAPSED,
  );

  return useCallback(
    (tabArg?: SidebarTab) => {
      // because of the overloads one of these must be specified
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- see above
      setSidebarTab((tab ?? tabArg)!);
      if (isDrawerViewCollapsed()) {
        setSidebarWidth(DEFAULT_SIDEBAR_WIDTH);
        setDrawerViewCollapsed(false);
      }
    },
    [
      isDrawerViewCollapsed,
      setDrawerViewCollapsed,
      setSidebarTab,
      setSidebarWidth,
      tab,
    ],
  );
}
